import React from "react";
import vasilis from "../static/images/vasilis2.png";
import overview from "../static/gifs/overview.gif";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { Button } from "../components/Button";

const OpenSourcePage = () => {

  // <style>
  //   #deploy {
  //     margin-left: 25%;
  //   }

  //   /* #slack-banner {
  //     -webkit-font-smoothing: antialiased;
  //   font-weight: 300;
  //   text-transform: none;
  //   letter-spacing: 0px;
  //   font-family: "Ek Mukta", sans-serif;
  //   cursor: pointer;
  //   color: inherit;
  //   text-decoration: none;
  //   } */
  //   #slack-banner>div {
  //     -webkit-font-smoothing: antialiased;
  //     font-weight: 300;
  //     text-transform: none;
  //     letter-spacing: 0px;
  //     font-family: "Ek Mukta", sans-serif;
  //     cursor: pointer;
  //     background-color: rgb(27, 68, 118);
  //     color: rgb(255, 255, 255);
  //     overflow: hidden;
  //     padding: 24px 16px;
  //     box-sizing: border-box;
  //     padding-left: 72px;
  //     padding-right: 72px;
  //   }

  //   #slack-banner>div>div {
  //     -webkit-font-smoothing: antialiased;
  //     font-weight: 300;
  //     text-transform: none;
  //     letter-spacing: 0px;
  //     font-family: "Ek Mukta", sans-serif;
  //     cursor: pointer;
  //     color: rgb(255, 255, 255);
  //     -webkit-box-align: center;
  //     align-items: center;
  //     -webkit-box-pack: justify;
  //     justify-content: space-between;
  //     display: flex;
  //     flex-direction: row;
  //   }

  //   #slack-logo {
  //     -webkit-font-smoothing: antialiased;
  //     font-weight: 300;
  //     text-transform: none;
  //     letter-spacing: 0px;
  //     font-family: "Ek Mukta", sans-serif;
  //     cursor: pointer;
  //     color: rgb(255, 255, 255);
  //     -webkit-box-align: center;
  //     align-items: center;
  //     display: flex;
  //     flex-direction: row;
  //   }

  //   #slack-join {
  //     -webkit-font-smoothing: antialiased;
  //     font-weight: 300;
  //     text-transform: none;
  //     letter-spacing: 0px;
  //     font-family: "Ek Mukta", sans-serif;
  //     cursor: pointer;
  //     color: rgb(255, 255, 255);
  //     position: relative;
  //     box-sizing: border-box;
  //     margin-top: 0px;
  //     margin-left: 35px;
  //   }

  //   #slack-join>img {
  //     -webkit-font-smoothing: antialiased;
  //     font-weight: 300;
  //     text-transform: none;
  //     letter-spacing: 0px;
  //     font-family: "Ek Mukta", sans-serif;
  //     cursor: pointer;
  //     color: rgb(255, 255, 255);
  //     aspect-ratio: auto 160 / 160;
  //     box-sizing: border-box;
  //     height: 160px;
  //     max-width: none;
  //     width: 160px;
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     transform: translate(-50%, -50%);
  //     display: block;
  //   }

  //   #slack-join>div {
  //     -webkit-font-smoothing: antialiased;
  //     letter-spacing: 0px;
  //     font-family: "Ek Mukta", sans-serif;
  //     cursor: pointer;
  //     border-radius: 30px;
  //     background-color: rgb(255, 255, 255);
  //     color: rgb(224, 84, 31);
  //     position: relative;
  //     padding: 6px 12px;
  //     font-weight: 600;
  //     line-height: 1;
  //     text-transform: uppercase;
  //     white-space: nowrap;
  //     box-sizing: border-box;
  //     font-size: 18px;
  //   }


  //   .mb-1 {
  //     margin-bottom: 4px !important;
  //   }
  // </style>

  return (

<html lang='en'>

<head>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title>Open Source | Code BGP</title>

  {/* <script>
    async function subscribe(id) {
      const email = document.querySelector(`#email${id}`).value;
      const url = "/newsletter";

      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        mode: 'no-cors', // no-cors, *cors, same-origin
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ "email": email }) // body data type must match "Content-Type" header
      });
      const text = await response.json();
      document.querySelector(`#notification${id}`).style.visibility = 'visible';
      if (!text.status.includes('success')) {
        // if ( document.getElementById(`status${id}`).classList.contains('bg-emerald-500') )
        document.getElementById(`status${id}`).style = "background-color: red";
        document.querySelector(`#status${id}`).innerHTML = 'FAIL';
        document.querySelector(`#message${id}`).innerHTML = text.status;
      } else {
        document.getElementById(`status${id}`).style = "background-color: green";
        document.querySelector(`#status${id}`).innerHTML = 'SUCCESS';
        document.querySelector(`#message${id}`).innerHTML = text.status;
      }
    }
  </script> */}
</head>

<body className="bg-gray-50">
  <div className="min-h-screen">
    <div className="relative overflow-hidden">
      <Header selectedPage="opensource"/>
      <main>

        <div className="mt-16 mb-10 bg-gray-900" style={{marginTop: "100px"}}>
          <div className="px-4 py-16 mx-auto max-w-7xl sm:py-24 sm:px-6 lg:px-8">
            <div className="text-center">
              {/* <!-- <h2 className="text-base font-semibold tracking-wide text-indigo-600 uppercase">ARTEMIS</h2> --> */}

              <p className="mt-1 text-4xl font-extrabold text-amber-500 sm:text-5xl sm:tracking-tight lg:text-6xl">Accurate
                and real-time BGP hijacking protection</p>
              <p className="max-w-xl mx-auto mt-5 text-xl text-white">ARTEMIS is an open-source tool that is maintained
                and supported by Code BGP, as a free community service. </p>
            </div>


            <div className="flex justify-center mt-8">
              <div className="inline-flex rounded-md shadow">
                <a href="https://demo.bgpartemis.org/login" target="_blank" rel="noreferrer"
                  className="inline-flex items-center justify-center px-5 py-3 text-base font-medium codebgp-button-primary border border-transparent rounded-md">
                  Try ARTEMIS Demo
                  <svg className="w-5 h-5 ml-3 -mr-1 codebgp-color-ebony" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor" aria-hidden="true">
                    <path
                      d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                    <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                  </svg>

                </a>
              </div>
              <div className="inline-flex ml-3">
                <Button
                  text="GitHub"
                  link="https://github.com/FORTH-ICS-INSPIRE/artemis"
                  customDivClasses="ml-5"
                  customLinkClasses="codebgp-button-secondary-2"
                  openInNewPage
                  openInNewPageArrow
                />
              </div>
            </div>
          </div>

          <div className="relative z-10 py-12 bg-gray-50">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="lg:text-left">
                <h2 className="text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl">
                  Defend your network against hijacks
                </h2>
                <p className="max-w-3xl mx-auto mt-4 text-xl text-center text-gray-500">
                  ARTEMIS is a tool that runs locally on the protected network, based on an on premise approach. It
                  monitors, detects, and can also automatically mitigate hijacks.
                </p>

              </div>


              {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
              <div className="py-12 sm:pb-0 bg-gray-50">
                <div className="max-w-xl px-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8">
                  <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-5 lg:gap-8">


                    <div>
                      <dt>
                        <div className="flex items-center justify-center w-12 h-12 mx-auto text-white codebgp-bg-color-ebony rounded-md sm:mx-0">
                          {/* <!-- Heroicon name: outline/globe-alt --> */}
                          <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                          </svg>
                        </div>
                        <p className="mt-5 text-lg font-medium leading-6 text-center text-gray-900 sm:text-left">Accurate Detection</p>
                      </dt>
                      <dd className="mt-2 text-base text-center text-gray-500 sm:text-left">
                        Minimize false positives and detect multiple types of hijacks based on state-of-the-art
                        research.
                      </dd>
                    </div>

                    <div>
                      <dt>
                        <div className="flex items-center justify-center w-12 h-12 mx-auto text-white codebgp-bg-color-ebony rounded-md sm:mx-0">
                          {/* <!-- Heroicon name: outline/scale --> */}
                          <svg style={{color: "white"}} xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                          </svg>
                        </div>
                        <p className="mt-5 text-lg font-medium leading-6 text-center text-gray-900 sm:text-left">Real-time Monitoring</p>
                      </dt>
                      <dd className="mt-2 text-base text-center text-gray-500 sm:text-left">
                        Consume BGP streaming data from RIPE RIS live, RouteViews and CAIDA BMP feeds, and local
                        monitors.
                      </dd>
                    </div>

                    <div>
                      <dt>
                        <div className="flex items-center justify-center w-12 h-12 mx-auto text-white codebgp-bg-color-ebony rounded-md sm:mx-0">
                          {/* <!-- Heroicon name: outline/scale --> */}
                          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                            <path fill-rule="evenodd"
                              d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                              clip-rule="evenodd" />
                          </svg>
                        </div>
                        <p className="mt-5 text-lg font-medium leading-6 text-center text-gray-900 sm:text-left">Extensible Design</p>
                      </dt>
                      <dd className="mt-2 text-base text-center text-gray-500 sm:text-left">
                        Build on a modern software architecture and stack. Easily extend or integrate ARTEMIS in custom
                        settings.
                      </dd>
                    </div>

                    <div>
                      <dt>
                        <div className="flex items-center justify-center w-12 h-12 mx-auto text-white codebgp-bg-color-ebony rounded-md sm:mx-0">
                          {/* <!-- Heroicon name: outline/scale --> */}
                          <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                          </svg>
                        </div>
                        <p className="mt-5 text-lg font-medium leading-6 text-center text-gray-900 sm:text-left">Permissive License</p>
                      </dt>
                      <dd className="mt-2 text-base text-center text-gray-500 sm:text-left">
                        Use ARTEMIS under a permissive BSD3 license without any strings attached.
                      </dd>
                    </div>

                    <div>
                      <dt>
                        <div className="flex items-center justify-center w-12 h-12 mx-auto text-white codebgp-bg-color-ebony rounded-md sm:mx-0">
                          {/* <!-- Heroicon name: outline/lightning-bolt --> */}
                          <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />

                          </svg>
                        </div>
                        <p className="mt-5 text-lg font-medium leading-6 text-center text-gray-900 sm:text-left">Community</p>
                      </dt>
                      <dd className="mt-2 text-base text-center text-gray-500 sm:text-left">
                        Be part of a community and immerse with other ARTEMIS users in <a style={{fontWeight: "bold"}} className="text-gray-900 hover:text-amber-500" target="_blank" rel="noreferrer"
                        href="https://join.slack.com/t/bgpartemis/shared_invite/zt-bjcsz4lt-OTDNWFoEcCC1q0Ke6AW6Lw">Slack</a>, <a style={{fontWeight: "bold"}} className="text-gray-900 hover:text-amber-500" target="_blank" rel="noreferrer"
                        href="https://twitter.com/codebgp">Twitter</a> and <a style={{fontWeight: "bold"}} className="text-gray-900 hover:text-amber-500" target="_blank" rel="noreferrer"
                        href="https://github.com/FORTH-ICS-INSPIRE/artemis">GitHub</a>.
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>

              {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
              <div className="relative overflow-hidden bg-gray-50">
                <div className="max-w-md px-4 mx-auto text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">

                  <div className="flex flex-row mt-10 sm:mt-0">
                    <div>
                      <img alt=""
                        className="hidden mt-10 rounded-lg codebgp-shadow-4 opacity-100 ring-1 ring-black ring-opacity-5 w-6/6 display sm:display md:hidden lg:flex "
                        src={overview} />
                      {/* <!-- <button id="deploy" type="submit" className="block w-2/6 px-4 py-3 mt-10 font-medium text-white bg-indigo-500 border-2 border-indigo-500 rounded-md shadow hover:bg-amber-600 hover:border-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-300 focus:ring-offset-gray-900"
                    onclick="javascript:window.open('https://github.com/FORTH-ICS-INSPIRE/artemis#how-to-install-and-setup', '_blank').focus();">
                    Deploy
                  </button> --> */}
                    </div>
                    <dl className="space-y-4 md:space-y-4 md:gap-x-8 md:gap-y-4"> </dl>
                  </div>
                </div>


                {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
                <section className="overflow-hidden bg-gray-50">
                  <div className="relative px-4 pb-12 mx-auto max-w-7xl sm:px-6 lg:px-8 sm:pt-20">
                    <div className="relative lg:flex lg:items-center">
                      <div className="hidden xl:block lg:shrink-0">
                        <img className="w-64 h-64 rounded-full xl:h-80 xl:w-80"
                          src={vasilis} alt=""/>
                      </div>

                      <div className="relative w-full lg:ml-10">
                        {/* <!-- <svg className="absolute top-0 left-0 text-indigo-200 -translate-x-8 -translate-y-24 opacity-50 h-36 w-36" stroke="currentColor" fill="none" viewBox="0 0 144 144" aria-hidden="true">
                  <path stroke-width="2" d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z" />
                </svg> --> */}
                        <blockquote className="relative w-full">
                          {/* <!-- <h3 style={{textAlign: "left"}}
                            className="TDB-Typography TDB-Typography--fs-heading-2 TDB-Typography--fw-bold TDB-Typography--color-neutral-main Talks__title">
                            ARTEMIS: Making BGP Operations Suck Less
                          </h3> --> */}
                          <div className="text-2xl font-medium leading-9 text-gray-900">
                            <div className="mb-5 text-base font-medium text-gray-900">
                              Listen to our CTO's interview on the <b><a target="_blank" rel="noreferrer"
                                  href="https://www.modem.show/post/s01e08/">modem.show</a></b> podcast. Vasileios
                              Kotronis
                              talks about ARTEMIS, and its benefits for BGP visibility and automation.
                            </div>
                            <iframe title="podcast"
                              src="https://anchor.fm/modulate-demodulate/embed/episodes/ARTEMIS-Making-BGP-Operations-Suck-Less-evlf7d/a-a5c5127"
                              height="100%" width="100%" frameborder="0" scrolling="no"></iframe>
                          </div>
                          <footer className="hidden mt-8 md:block">
                            <div className="flex">
                              <div className="shrink-0 lg:hidden">
                                <img className="w-12 h-12 rounded-full"
                                  src={vasilis} alt=""/>
                              </div>
                              <div className="ml-4 lg:ml-0">

                              </div>
                            </div>
                          </footer>
                        </blockquote>
                      </div>
                    </div>
                  </div>
                </section>



                {/* <!--

<a id="slack-banner" className="" target="_blank" rel="noreferrer"
href="https://join.slack.com/t/bgpartemis/shared_invite/zt-bjcsz4lt-OTDNWFoEcCC1q0Ke6AW6Lw">
<div color="body" overflow="hidden" className="mt-20 rounded-lg Box-u6r7lq-0 cGBHCp">
  <div display="flex" className="Flex-j9ashm-0 jBRTRI">
    <div id="slack-logo" display="flex" className="Flex-j9ashm-0 hXBODN">
      <img
      src="https://images.ctfassets.net/6yom6slo28h2/3MwkhKgPEQERlVwSuvB5YO/debbaea14d9d6c9ac5d75228a20882bc/slack-logo.svg"
      alt="" loading="lazy" className="float-left mr-5" width="45" height="45" />
      <div font-size="18,20" font-weight="normal" className="Box-u6r7lq-0 jCqRcn">
        <div font-size="24,26" className="Box-u6r7lq-0 pJWEp">Join the ARTEMIS Slack Community</div>
        <div className="Box-u6r7lq-0 fOoimM">Be part of a community of ARTEMIS users who can help you along
          the
        way.</div>
      </div>
    </div>
    <div id="slack-join" className="Box-u6r7lq-0 hPxnsK">
      <img
      src="https://images.ctfassets.net/6yom6slo28h2/1PzENb08Q4elucvlmOBmQB/c4a5c4d03137b335001b5921f1b41217/slack-promo-sonar.svg"
      alt="" transform="translate(-50%, -50%)" display="[object Object]" loading="lazy"
      className="Image__StyledImage-dds9yx-0 lgaQhC" width="160" height="160" />
      <div font-size="16,18" font-weight="bold" color="darkOrange" className="Box-u6r7lq-0 ixWoUh">Join Now
      </div>
    </div>
  </div>
</div>
</a>
--> */}

              </div>
            </div>

            <div className="bg-gray-50">
              <div
                className="px-4 py-6 mx-auto max-w-7xl sm:px-6 lg:py-6 lg:px-8 lg:flex lg:items-center lg:justify-between">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  <span className="block text-center sm:text-left">Ready to dive in?</span>
                  <span className="block text-center text-amber-500 sm:text-left">Join the ARTEMIS Slack Community</span>
                  <p style={{fontWeight: "normal"}} className="mt-4 text-lg leading-6 text-center text-gray-900 sm:text-left">Be part of a community of ARTEMIS users who can help
                    you along the way</p>
                </h2>

                <div className="flex mt-8 lg:mt-0 lg:shrink-0">
                  <div className="inline-flex mx-auto rounded-md shadow sm:mx-0">
                    <a href="https://join.slack.com/t/bgpartemis/shared_invite/zt-bjcsz4lt-OTDNWFoEcCC1q0Ke6AW6Lw"
                      target="_blank" rel="noreferrer"
                      className="inline-flex items-center justify-center px-5 py-3 text-base font-medium codebgp-button-primary border border-transparent rounded-md">
                      Join Now
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- <div className="mt-10 mb-10 bg-white">
              <div className="max-w-2xl px-4 py-16 mx-auto text-left sm:py-20 sm:px-12 lg:px-8">
                <div className="absolute bg-blue-700 rounded-full pointer-events-none w-96 h-96 bg-opacity-60 -right-20 bottom-20"></div>

                <div className="absolute bg-amber-400 rounded-full pointer-events-none w-96 h-96 bg-opacity-40 -left-20 bottom-96"></div>
                <h2 className="mt-2 text-3xl font-extrabold text-indigo-500 sm:text-4xl">
                  <span className="relative z-10 block">Get notified when we are launching.</span>
                </h2>
                <form onsubmit="event.preventDefault(); subscribe(2)"
                  className="relative z-10 mt-12 sm:max-w-xl sm:mx-auto lg:mx-0">
                  <div className="sm:flex">
                    <div className="flex-1 min-w-0">
                      <label for="email2" className="sr-only">Email address</label>
                      <input id="email2" type="email" name="email" placeholder="Enter your email" className="block w-full px-4 py-3 text-base text-gray-900 placeholder-gray-500 border-2 border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" />
                    </div>
                    <div className="mt-3 sm:mt-0 sm:ml-3">
                      <button type="submit" className="block w-full px-4 py-3 font-medium text-white bg-indigo-500 border-2 border-indigo-500 rounded-md shadow hover:bg-amber-600 hover:border-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-300 focus:ring-offset-gray-900">
                        Notify me
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div> --> */}
        </div>
        </div>
      </main>
      <Footer />
    </div>
  </div>
</body>

</html>
)
}

export default OpenSourcePage